export default function () {
  return {
    ADULT_DETAIL: "details",
    ADULT__HOME: "home",
    ADULT__SHOWS: "shows",
    ADULT__MOVIES: "movies",
    ADULT__NEWS: "news",
    ADULT__SPORTS: "sports",
    ADULT__RADIO: "radio",
    ADULT__LIVE: "channels",
    ADULT__SEARCH: "search",
    ADULT__MY_LIST: "my_list",
    ADULT__GUIDE: "ADULT__GUIDE",
    ADULT__WATCH_VOD: "ADULT__WATCH_VOD",
    ADULT__WATCH_SHOWS: "ADULT__WATCH_SHOWS",
    ADULT__WATCH_EXTERNAL: "ADULT__WATCH_EXTERNAL",
    ADULT__WATCH_LIVE: "ADULT__WATCH_LIVE",
    ADULT__THEMATIC: "ADULT__THEMATIC",
    ADULT__DIRECTORY: "ADULT__DIRECTORY",
    KIDS__HOME: "kids_home",
    KIDS__CATEGORY: "KIDS__CATEGORY",
    KIDS__PROGRAMS: "program",
    KIDS__CHARACTERS: "characters",
    KIDS__CHARACTERS_SPECIFIC: "KIDS__CHARACTERS_SPECIFIC",
    KIDS__SONGS: "songs",
    KIDS__LIVE: "KIDS__LIVE",
    KIDS__MY_LIST: "kids_my_list",
    KIDS__SEARCH: "kids_search",
    KIDS__WATCH_VOD: "KIDS__WATCH_VOD",
    KIDS__WATCH_SONGS: "KIDS__WATCH_SONGS",
  };
}
